import { FC, useCallback, useState } from 'react';
import { Role } from '@shared/enums/role';
import { useStyles } from './ClientSurveysPage.styles';
import { useClientSurveysPageData } from './hooks/useClientSurveysPageData';
import { DataState } from '@shared/enums/DataState';
import { DataErrorView } from '@shared/components/DataErrorView';
import { useAuth } from '@shared/hooks/useAuth';
import { Button, MenuItem, Select, Stack } from '@mui/material';
import { ClientSurveysTable } from './copmonents/ClientSurveysTable';
import { ClientSurveyModal } from './copmonents/ClientSurveyModal/ClientSurveyModal';
import { ClientSurveyData } from '@modules/HappinessModule/interfaces/ClientSurveyData';
import { setUpsertSurvey } from './redux/actions';
import { useDispatch } from 'react-redux';
import BarChartIcon from '@mui/icons-material/BarChart';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { NEW_MODAL_KEY } from './constants/constants';
import { filterCsatSurveysByDateRange } from './helpers/helpers';

export const ClientSurveysPage: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [filterByDateRange, setFilterByDateRange] = useState<number>(new Date().getFullYear());

  const { clientSurveys, categoriesList, upsertSurvey, upsertState } = useClientSurveysPageData();
  const { currentUser } = useAuth();
  const openModal = useCallback(
    (newUpsertSurvey: ClientSurveyData | typeof NEW_MODAL_KEY) => {
      dispatch(setUpsertSurvey(newUpsertSurvey));
    },
    [dispatch]
  );

  const closeModal = useCallback(() => {
    dispatch(setUpsertSurvey(undefined));
  }, [dispatch]);

  const surveyDates = [...new Set(clientSurveys.data?.map((survey) => new Date(survey.date).getFullYear()))];

  const isLoading = clientSurveys.state === DataState.Pending;
  const isRejected = clientSurveys.state === DataState.Rejected;
  const isReadOnlyMode = !currentUser?.rockyRoles.includes(Role.csatMember);

  return (
    <div className={styles.root}>
      {isRejected ? (
        <DataErrorView error={clientSurveys.error} />
      ) : (
        <div className={styles.card}>
          <div className={styles.header}>
            <Stack direction="row" alignItems="center" gap="8px">
              <div className={styles.title}>Client satisfaction surveys</div>
              <Button
                startIcon={<BarChartIcon />}
                href="https://lookerstudio.google.com/u/0/reporting/b470d9a8-c400-4147-8c71-16ad4375056e"
                target="_blank"
              >
                View results report
              </Button>
            </Stack>
            {!isLoading && !isReadOnlyMode && (
              <Stack direction="row" alignItems="center" gap="8px">
                <Select
                  size="small"
                  sx={{ minWidth: '130px' }}
                  value={filterByDateRange}
                  onChange={(e) => {
                    setFilterByDateRange(Number(e.target.value));
                  }}
                >
                  {surveyDates.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                  <MenuItem value={0}>All</MenuItem>
                </Select>
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => openModal(NEW_MODAL_KEY)}
                  variant="contained"
                >
                  Add question
                </Button>
              </Stack>
            )}
          </div>
          <ClientSurveysTable
            clientSurveys={{
              ...clientSurveys,
              data: filterCsatSurveysByDateRange({
                csatSurveys: clientSurveys.data ?? [],
                filterByDateRange,
              }),
            }}
            isReadOnlyMode={isReadOnlyMode}
            openModal={openModal}
          />
        </div>
      )}
      {upsertSurvey && (
        <ClientSurveyModal
          upsertState={upsertState}
          clientSurvey={upsertSurvey}
          closeModal={closeModal}
          categoriesList={categoriesList}
          clientSurveysList={clientSurveys.data ?? []}
        />
      )}
    </div>
  );
};
