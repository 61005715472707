import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilterOptionsState,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Field, FieldProps, FormikProps, getIn, useFormikContext } from 'formik';
import { DepartmentData } from '@shared/interfaces/DepartmentData';
import { useAllDepartments } from '@shared/hooks/useAllDepartments';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { FlagFormFields, FlagFormFieldsNewProjectAssignment } from '../../../constants/FlagFormFields';
import { Workloads } from '@shared/enums/Workloads';
import { DatePicker } from '@mui/x-date-pickers';
import { rockySwitcherStyles } from '@styles/rockySwitcherStyles';
import { NewProjectSuggestedRaise } from '@modules/HappinessModule/enums/NewProjectSuggestedRaise';
import { useMyEvalData } from '@modules/EvaluationModule/pages/UserEvalPage/hooks/useMyEvalData';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { NewProjectAssignmentNearestEval } from '@modules/HappinessModule/interfaces/NewProjectAssignmentData';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { FlagFieldBlockPreview } from '@modules/HappinessModule/pages/FlagPage/pages/EditFlagFormPage/components/FlagPreview/components/FlagFieldBlockPreview';
import AddIcon from '@mui/icons-material/Add';
import { format, isDate, isValid, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { editFlagAction } from '@modules/HappinessModule/pages/FlagPage/pages/CreateFlagFormPage/redux/actions';
import { useParams } from 'react-router-dom';
import { mapNewProjectAssignmentDataToPayload } from '@modules/HappinessModule/pages/FlagPage/helpers/mapNewProjectAssignmentDataToPayload';
import { LoadingButton } from '@mui/lab';
import { addFlagDataStateSelector } from '@modules/HappinessModule/pages/FlagPage/pages/CreateFlagFormPage/redux/selectors';
import { DataState } from '@shared/enums/DataState';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { RocketLoader } from '@shared/components/RocketLoader';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  user: UserInfo;
  setIsNewHomeProjectFoundSetted: (value: boolean) => void;
}

export const NewHomeProjectFound: FC<Props> = ({ isOpen, handleClose, user, setIsNewHomeProjectFoundSetted }) => {
  const { flagId } = useParams();
  const userId = user.id;
  const rockySwitcher = rockySwitcherStyles();
  const allDepartments = useAllDepartments();
  const { values, setFieldValue, errors, initialValues } = useFormikContext<FlagFields>();
  const userEvals = useMyEvalData(userId);
  const isUserEvalsLoaded = userEvals.data?.history && userEvals.data?.history.length > 0;
  const userEval = userEvals.data?.history.find(
    (evalItem) => evalItem.status === EvaluationStatus.Ongoing || EvaluationStatus.Upcoming
  );
  const dispatch = useDispatch();
  const [isEvalNotesShown, setIsEvalNotesShown] = useState(false);
  const [isTimeOffNotesShown, setIsTimeOffNotesShown] = useState(false);

  const { loadingState } = useSelector(addFlagDataStateSelector);
  const isPatching = loadingState === DataState.Pending;

  const handleCloseWithReset = () => {
    handleClose();
    setFieldValue(FlagFormFields.newHomeResolution.name, initialValues.newHomeResolution);
    setFieldValue(FlagFormFields.newProjectAssignment.name, null);
  };

  const startDateError = getIn(errors, FlagFormFieldsNewProjectAssignment.startDate.name);
  const isSavingDisabled =
    !values.newProjectAssignment?.project ||
    !values.newProjectAssignment?.startDate ||
    Object.values(errors.newProjectAssignment ?? {}).length > 0;

  const saveFlagWithNewProjectFound = () => {
    if (!flagId || !values.newProjectAssignment?.nearestEval) return;
    dispatch(
      editFlagAction({
        fields: {
          nhEmailSentStatus: values.nhEmailSentStatus,
          isConfidential: values.isConfidential,
          isNewHomeNeeded: values.isNewHomeNeeded,
          pros: values.pros,
          cons: values.cons,
          softSkills: values.softSkills,
          newHomeResolution: values.newHomeResolution,
          numSubmissions: values.numSubmissions,
          numTICIFailed: values.numTICIFailed,
          numOptionsShown: values.numOptionsShown,
          newProjectAssignment: mapNewProjectAssignmentDataToPayload(values.newProjectAssignment),
        },
        flagId,
        isNoNavigation: true,
      })
    );
  };

  useEffect(() => {
    const nearestEval: NewProjectAssignmentNearestEval | null = userEval
      ? {
          date: userEval.date,
          status: userEval.status,
          id: userEval.evaluationId,
        }
      : null;
    setFieldValue(FlagFormFieldsNewProjectAssignment.workload.name, Workloads.FullTime);
    setFieldValue(FlagFormFieldsNewProjectAssignment.isSowSigned.name, true);
    setFieldValue(FlagFormFieldsNewProjectAssignment.suggestedRaise.name, NewProjectSuggestedRaise.Same);
    setFieldValue(FlagFormFieldsNewProjectAssignment.nearestEval.name, nearestEval);
    setFieldValue(FlagFormFieldsNewProjectAssignment.currentSeniority.name, user.seniority);
    setFieldValue(FlagFormFieldsNewProjectAssignment.addHRSupport.name, false);
  }, [userEval]);

  useEffect(() => {
    if (!isPatching && loadingState === DataState.Fulfilled) {
      setIsNewHomeProjectFoundSetted(true);
      dispatch(displayNotification('Flag was successfully updated'));
      handleClose();
    }
  }, [isPatching]);

  const isAllNesessaryDataLoaded = isUserEvalsLoaded;

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '524px',
          },
        },
      }}
      open={isOpen}
      onClose={handleCloseWithReset}
    >
      {!isAllNesessaryDataLoaded ? (
        <Box sx={{ margin: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <RocketLoader />
        </Box>
      ) : (
        <>
          <DialogTitle
            sx={{
              marginTop: '20px',
            }}
          >
            New project found
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseWithReset}
            sx={{
              position: 'absolute',
              right: 8,
              top: 28,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Box>
              <Typography variant="subtitle1">Project information</Typography>
              <Stack
                sx={{
                  mt: '16px',
                  rowGap: '16px',
                }}
              >
                <Field
                  name={FlagFormFieldsNewProjectAssignment.project.name}
                  component={Autocomplete}
                  getOptionLabel={(option: DepartmentData) => option.name}
                  filterOptions={(options: DepartmentData[], state: FilterOptionsState<DepartmentData>) => {
                    const searchString = String(state.inputValue).toLowerCase();
                    return options.filter((option) => option.name.toLowerCase().includes(searchString));
                  }}
                  disableClearable
                  value={values.newProjectAssignment?.project}
                  onChange={(_: SyntheticEvent, newValue: DepartmentData) =>
                    setFieldValue(FlagFormFieldsNewProjectAssignment.project.name, newValue)
                  }
                  options={allDepartments.data ?? []}
                  renderInput={(params: FormikProps<TextFieldProps>) => (
                    <TextField
                      {...params}
                      label={FlagFormFieldsNewProjectAssignment.project.label}
                      placeholder="Please select"
                    />
                  )}
                />

                <Grid container columnSpacing={2}>
                  <Grid item xs={6}>
                    <DatePicker
                      label={FlagFormFieldsNewProjectAssignment.startDate.label}
                      views={['year', 'month', 'day']}
                      openTo="month"
                      inputFormat="dd MMM yyyy"
                      value={values.newProjectAssignment?.startDate ?? null}
                      disableMaskedInput
                      onChange={(newDate: unknown) => {
                        let parsedNewDate = newDate;
                        if (isDate(newDate) && isValid(newDate)) {
                          parsedNewDate = (parsedNewDate as Date).toISOString();
                        }
                        setFieldValue(FlagFormFieldsNewProjectAssignment.startDate.name, parsedNewDate, true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onKeyDown={(e) => e.preventDefault()}
                          sx={{
                            caretColor: 'transparent',
                          }}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'Select date',
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          fullWidth
                          helperText={startDateError}
                          error={startDateError}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      type="select"
                      name={FlagFormFieldsNewProjectAssignment.workload.name}
                      component={({ field }: FieldProps) => (
                        <FormControl fullWidth>
                          <InputLabel>{FlagFormFieldsNewProjectAssignment.workload.label}</InputLabel>
                          <Select
                            variant="outlined"
                            label={FlagFormFieldsNewProjectAssignment.workload.label}
                            {...field}
                          >
                            {Object.values(Workloads).map((workload) => (
                              <MenuItem key={workload} value={workload}>
                                {workload}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
                <Field
                  name={FlagFormFieldsNewProjectAssignment.isSowSigned.name}
                  type="checkbox"
                  component={({ field }: FieldProps) => (
                    <FormControlLabel
                      control={
                        <Switch
                          {...field}
                          className={rockySwitcher.switcher}
                          checked={values.newProjectAssignment?.isSowSigned}
                          onChange={(_, checked) =>
                            setFieldValue(FlagFormFieldsNewProjectAssignment.isSowSigned.name, checked)
                          }
                        />
                      }
                      label={
                        <Typography
                          variant="subtitle1"
                          sx={{
                            marginLeft: '10px',
                          }}
                        >
                          {FlagFormFieldsNewProjectAssignment.isSowSigned.label}
                        </Typography>
                      }
                    />
                  )}
                />
              </Stack>
            </Box>
            <Box
              sx={{
                marginTop: '24px',
              }}
            >
              <Typography variant="subtitle1">Compensation and time off</Typography>
              <Stack
                sx={{
                  mt: '16px',
                }}
                rowGap="16px"
              >
                <Stack direction="row" alignItems="center" columnGap="16px">
                  <Field
                    type="select"
                    name={FlagFormFieldsNewProjectAssignment.suggestedRaise.name}
                    component={({ field }: FieldProps) => (
                      <FormControl
                        fullWidth
                        sx={{
                          flex: 1,
                        }}
                      >
                        <InputLabel>{FlagFormFieldsNewProjectAssignment.suggestedRaise.label}</InputLabel>
                        <Select
                          variant="outlined"
                          label={FlagFormFieldsNewProjectAssignment.suggestedRaise.label}
                          {...field}
                        >
                          {Object.values(NewProjectSuggestedRaise).map((newProjectSuggestedRaise) => (
                            <MenuItem key={newProjectSuggestedRaise} value={newProjectSuggestedRaise}>
                              {newProjectSuggestedRaise}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <FlagFieldBlockPreview
                    label={FlagFormFieldsNewProjectAssignment.currentSeniority.label}
                    value={values.newProjectAssignment?.currentSeniority}
                  />
                  {isUserEvalsLoaded && values.newProjectAssignment?.nearestEval?.date && (
                    <FlagFieldBlockPreview
                      label={FlagFormFieldsNewProjectAssignment.nearestEval.label}
                      value={
                        values.newProjectAssignment?.nearestEval?.status === EvaluationStatus.Ongoing
                          ? 'Ongoing'
                          : format(parseISO(values.newProjectAssignment.nearestEval.date), 'dd MMM yyyy')
                      }
                    />
                  )}
                </Stack>
                {isEvalNotesShown ? (
                  <Field name={FlagFormFieldsNewProjectAssignment.evaluationNotes.name}>
                    {({ field }: FieldProps) => (
                      <TextField
                        size="medium"
                        label={FlagFormFieldsNewProjectAssignment.evaluationNotes.label}
                        multiline
                        minRows={2}
                        variant="outlined"
                        fullWidth
                        {...field}
                        value={field.value ?? ''}
                      />
                    )}
                  </Field>
                ) : (
                  <Link
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => setIsEvalNotesShown(true)}
                  >
                    <AddIcon sx={{ marginRight: '4px' }} /> Add notes about evaluation and promotion
                  </Link>
                )}
                {isTimeOffNotesShown ? (
                  <Field name={FlagFormFieldsNewProjectAssignment.timeOffNotes.name}>
                    {({ field }: FieldProps) => (
                      <TextField
                        size="medium"
                        label={FlagFormFieldsNewProjectAssignment.timeOffNotes.label}
                        multiline
                        minRows={2}
                        variant="outlined"
                        fullWidth
                        {...field}
                        value={field.value ?? ''}
                      />
                    )}
                  </Field>
                ) : (
                  <Link
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => setIsTimeOffNotesShown(true)}
                  >
                    <AddIcon sx={{ marginRight: '4px' }} /> Add notes about time off
                  </Link>
                )}
              </Stack>
            </Box>
            <Stack
              rowGap="7px"
              sx={{
                mt: '14px',
              }}
            >
              <Alert icon={<EmailOutlinedIcon color="disabled" />} severity="info">
                <Typography variant="body2" fontSize="14px">
                  An email with these details will be sent to new DM with a copy to PDM, Talent Operations, Background
                  Check, and IT Support
                </Typography>
                <Field
                  name={FlagFormFieldsNewProjectAssignment.addHRSupport.name}
                  type="checkbox"
                  component={({ field }: FieldProps) => (
                    <FormControlLabel
                      control={
                        <Switch
                          {...field}
                          className={rockySwitcher.switcher}
                          checked={values.newProjectAssignment?.addHRSupport}
                          onChange={(_, checked) =>
                            setFieldValue(FlagFormFieldsNewProjectAssignment.addHRSupport.name, checked)
                          }
                          sx={{ marginTop: '7px' }}
                        />
                      }
                      label={
                        <Typography
                          variant="subtitle1"
                          sx={{
                            marginLeft: '10px',
                            marginTop: '7px',
                          }}
                        >
                          {FlagFormFieldsNewProjectAssignment.addHRSupport.label}
                        </Typography>
                      }
                    />
                  )}
                />
              </Alert>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseWithReset}>
              Cancel
            </Button>
            <LoadingButton
              loading={isPatching}
              variant="contained"
              color="primary"
              onClick={saveFlagWithNewProjectFound}
              disabled={isSavingDisabled || isPatching}
            >
              Save and send
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
