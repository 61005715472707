import React, { FC } from 'react';
import { useStyles } from './EvalEndOfProbationPage.styles';
import { useDispatch, useSelector } from 'react-redux';
import { evalPageDataSelector } from '../EvalPage/redux/selectors';
import { DataState } from '@shared/enums/DataState';
import { EvaluationType } from '@modules/EvaluationModule/enums/EvaluationType';
import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { useAuth } from '@shared/hooks/useAuth';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { AccessDeniedView } from '@shared/components/DataErrorView/components/AccessDeniedView';
import { EvalEndOfProbation } from '../EvalPage/components/EvalEndOfProbation';
import { setFullEop } from '../EvalPage/redux/actions';

export const EvalEndOfProbationPage: FC = () => {
  const styles = useStyles();

  const evalData = useSelector(evalPageDataSelector);
  const { data: evalPageData, state: evalPageDataState } = evalData;

  const isPageLoading = evalPageDataState === DataState.Pending;
  const isEop = evalPageData?.typeOfInteraction === EvaluationType.EndOfProbation;

  const dispatch = useDispatch();

  const { currentUser } = useAuth();
  const user = useSelector(userSelector);
  const isCurrentUserEvaluator = user?.primaryReportsTo.id === currentUser?.id;
  const isEvalUpcoming = evalPageData?.status === EvaluationStatus.Upcoming;

  return (
    <div className={styles.root}>
      {isPageLoading || (isEop && isCurrentUserEvaluator && !isEvalUpcoming) ? (
        <EvalEndOfProbation
          evalData={evalData}
          onSubmit={({ eopResult, nextEvalDate, failingReason }) => {
            dispatch(
              setFullEop({
                eopResult: eopResult,
                next_evaluation_date: nextEvalDate,
                notes: failingReason,
              })
            );
          }}
        />
      ) : (
        <AccessDeniedView inComponent />
      )}
    </div>
  );
};
