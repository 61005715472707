import React, { FC, memo, useCallback, useMemo } from 'react';

import { RockyButton } from '@shared/components/RockyButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { EditClientSurveyData } from '@modules/HappinessModule/interfaces/EditClientSurveyData';
import * as Yup from 'yup';
import { ClientSurveyData } from '@modules/HappinessModule/interfaces/ClientSurveyData';
import { v4 } from 'uuid';
import { createNewClientSurvey, removeClientSurvey, updateClientSurvey } from '../../redux/actions';
import { Autocomplete, Box, FormControl, FormControlLabel, RadioGroup, Stack, Radio } from '@mui/material';
import { Textarea } from '@shared/components/Textarea';
import { COLORS } from '@styles/colors';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { AsyncData } from '@shared/interfaces/asyncData';
import { DataState } from '@shared/enums/DataState';
import { RockyDatePicker } from '@shared/components/RockyDatePicker';
import { getMinSurveySendingDate, getSurveySendingValidDate } from '../../helpers/helpers';
import { isSameDay, parseISO } from 'date-fns';
import { TrueFalse } from '@shared/enums/TrueFalse';
import { NEW_MODAL_KEY } from '../../constants/constants';

const fieldsSchema = {
  text: Yup.string().required('CSAT name is required'),
  date: Yup.string().required('Date is required'),
  category: Yup.string().required('Category is required'),
  description: Yup.string().optional().nullable(),
  isSpecial: Yup.boolean().required(),
};

const validationSchema = Yup.object().shape(fieldsSchema);

interface Props {
  upsertState?: DataState;
  closeModal: () => void;
  clientSurvey: ClientSurveyData | typeof NEW_MODAL_KEY;
  clientSurveysList: ClientSurveyData[];
  categoriesList: AsyncData<string[]>;
}

const useStyles = makeStyles({
  errorMsg: {
    display: 'block',
    position: 'absolute',
    top: '100%',
    left: 0,
    fontSize: '14px',
    color: COLORS.STATUS.CRITICAL,
  },
  answerBlockComments: {
    marginTop: '15px',
    width: '100%',
    minHeight: 80,
    border: 'none',
    boxShadow: `inset 0 0 0 1px ${COLORS.INPUTBORDER}`,
    color: COLORS.TEXT.PRIMARY,
    fontWeight: '400',
    lineHeight: '130%',
    borderRadius: 4,
    padding: '10px 15px',
    resize: 'none',

    '&:focus': {
      boxShadow: `inset 0 0 0 2px ${COLORS.ACTION.PRIMARY}`,
      outline: 'none',
    },
  },
});

const ClientSurveyModalComponent: FC<Props> = ({
  closeModal,
  upsertState,
  clientSurvey,
  clientSurveysList,
  categoriesList,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const isClientSurveyNew = clientSurvey === NEW_MODAL_KEY;

  const mode: 'create' | 'update' = useMemo(() => (isClientSurveyNew ? 'create' : 'update'), [clientSurvey]);

  const clientSurveyPostPut: EditClientSurveyData = useMemo((): EditClientSurveyData => {
    if (!isClientSurveyNew) {
      return {
        id: clientSurvey.id,
        category: clientSurvey.category,
        date: clientSurvey.date,
        description: clientSurvey.description,
        text: clientSurvey.text,
        isSpecial: clientSurvey.isSpecial,
      };
    }

    return {
      id: v4(),
      category: '',
      date: '',
      description: '',
      text: '',
      isSpecial: false,
    };
  }, [clientSurvey, isClientSurveyNew]);

  const onCloseModal = useCallback((): void => {
    closeModal();
  }, [closeModal]);

  const onSubmit = useCallback(
    (values: EditClientSurveyData) => {
      if (mode === 'create') {
        dispatch(
          createNewClientSurvey({
            // All of the were validate to be defined
            category: values.category as string,
            date: values.date as string,
            description: values.description as string,
            text: values.text as string,
          })
        );
      } else {
        dispatch(
          updateClientSurvey({
            id: values.id,
            category: values.category,
            date: values.date,
            description: values.description,
            text: values.text,
            isSpecial: values.isSpecial,
          })
        );
      }
    },
    [dispatch, mode]
  );

  const onSurveyDelete = useCallback(() => {
    if (isClientSurveyNew) return;

    dispatch(removeClientSurvey({ id: clientSurvey.id }));
  }, [dispatch, clientSurvey, isClientSurveyNew]);

  const shouldDisableDate = useCallback(
    (date: string) => {
      const dateToCheck = new Date(date).toISOString();
      return clientSurveysList.some((clientSurvey) => isSameDay(parseISO(clientSurvey.date), parseISO(dateToCheck)));
    },
    [clientSurveysList]
  );

  const { errors, values, handleChange, isValid, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: clientSurveyPostPut,
    validationSchema,
    validateOnMount: false,
    onSubmit,
  });

  const onDateChange = useCallback(
    (value) => {
      if (!value) return;
      const date = getSurveySendingValidDate(new Date(value)).toISOString();
      setFieldValue('date', date);
    },
    [setFieldValue]
  );

  const onCategoryChange = useCallback(
    (_: React.SyntheticEvent, value: string) => {
      setFieldValue('category', value);
    },
    [setFieldValue]
  );

  const onDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setFieldValue('description', e.target.value);
    },
    [setFieldValue]
  );

  return (
    <Dialog open={!!clientSurvey} onClose={onCloseModal}>
      <DialogTitle>{isClientSurveyNew ? 'Add new question' : 'Edit question'}</DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <TextField
          disabled={upsertState === DataState.Pending}
          autoFocus
          variant="outlined"
          placeholder="Question"
          label="Question"
          type="text"
          sx={{ width: '50ch' }}
          name={'text'}
          value={values.text}
          helperText={errors.text && <span>{errors.text}</span>}
          onChange={handleChange}
          error={Boolean(errors.text)}
        />
        <div>
          <Textarea
            disabled={upsertState === DataState.Pending}
            value={values.description}
            className={classNames(styles.answerBlockComments)}
            isAutosize={true}
            placeholder={'Description...'}
            maxLength={4096}
            name={'description'}
            onChange={onDescriptionChange}
          />
          <div className={styles.errorMsg}>{errors.description && <div>{errors.description}</div>}</div>
        </div>
        <Autocomplete
          disabled={values.text === '' || upsertState === DataState.Pending}
          sx={{ marginTop: '15px' }}
          value={values.category}
          disableClearable
          fullWidth
          onChange={onCategoryChange}
          loading={categoriesList.state === DataState.Pending}
          options={categoriesList.data ?? []}
          renderInput={(params) => <TextField {...params} label="Category" name="category" />}
        />
        <RadioGroup
          sx={{ marginTop: '15px' }}
          onChange={(e) => {
            setFieldValue('isSpecial', e.target.value === TrueFalse.True);
          }}
          value={values.isSpecial ? TrueFalse.True : TrueFalse.False}
        >
          <FormControl>
            <Stack direction="row" spacing={0.5}>
              <FormControlLabel control={<Radio required />} label="For monthly schedule" value={TrueFalse.False} />
              <FormControlLabel control={<Radio required />} label="Quarterly" value={TrueFalse.True} />
            </Stack>
          </FormControl>
        </RadioGroup>
        <Box sx={{ marginTop: '15px' }}>
          <RockyDatePicker
            renderInput={() => <></>}
            minDate={getMinSurveySendingDate().toISOString()}
            disabled={values.text === '' || upsertState === DataState.Pending}
            onChange={onDateChange}
            value={values.date ?? null}
            shouldDisableDate={shouldDisableDate}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr auto',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'grid', gridGap: '10px', gridTemplateColumns: 'auto auto' }}>
          <RockyButton
            action
            type="submit"
            onClick={handleSubmit}
            isLoading={upsertState === DataState.Pending}
            disabled={!isValid}
          >
            Save
          </RockyButton>
          <RockyButton inverted onClick={onCloseModal}>
            Cancel
          </RockyButton>
        </Box>
        <Box></Box>
        <Box sx={{ justifySelf: 'end' }}>
          {!isClientSurveyNew && (
            <RockyButton onClick={onSurveyDelete} dangerInverted disabled={upsertState === DataState.Pending}>
              Delete
            </RockyButton>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export const ClientSurveyModal = memo(ClientSurveyModalComponent);
