import React, { FC, memo, ReactNode, useMemo } from 'react';
import { useStyles } from './MyTeamEvalReport.styles';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';
import { DataErrorView } from '@shared/components/DataErrorView';
import { MyTeamEvalData } from '@modules/EvaluationModule/interfaces/MyTeamEvalData';
import { InfoView } from '@shared/components/InfoView/InfoView';
import EmptyEval from '@assets/img/EmptyEval.png';
import { EvaluationInfoViewTexts } from '@modules/EvaluationModule/enums/EvaluationInfoViewTexts';
import { EvalFilters } from '@modules/EvaluationModule/pages/MyPeopleEvalPage/enums/EvalFilters';

interface Props {
  tableTitle: string;
  myTeamEvalData: AsyncData<MyTeamEvalData[]>;
  children: ReactNode;
  header?: ReactNode;
  sideBlock?: ReactNode;
  statusFilter?: EvalFilters;
}

const MyTeamEvalReportComponent: FC<Props> = ({
  tableTitle,
  myTeamEvalData,
  children,
  header,
  sideBlock,
  statusFilter,
}) => {
  const styles = useStyles();
  const isDataLoading = myTeamEvalData.state === DataState.Pending;

  const notFoundMessage = useMemo(() => {
    if (statusFilter === EvalFilters.Upcoming) {
      return EvaluationInfoViewTexts.NoUpcomingEvaluations;
    }

    if (statusFilter === EvalFilters.Ongoing) {
      return EvaluationInfoViewTexts.NoOngoingEvaluations;
    }

    // if (statusFilter === EvalFilters.Overdue) {
    return EvaluationInfoViewTexts.NoOngoingEvaluations;
    // }

    // return EvaluationInfoViewTexts.NoOrphanedEvaluations;
  }, [statusFilter]);

  return myTeamEvalData.state === DataState.Rejected ? (
    <DataErrorView error={myTeamEvalData.error} />
  ) : (
    <div className={styles.root}>
      {header && header}

      <div className={styles.tableContainer}>
        <div className={styles.header}>
          <div className={styles.title}>
            {tableTitle}
            {sideBlock}
          </div>
        </div>
        {!isDataLoading && myTeamEvalData.data && myTeamEvalData.data?.length < 1 ? (
          <InfoView icon={EmptyEval} text={notFoundMessage} inComponent />
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export const MyTeamEvalReport = memo(MyTeamEvalReportComponent);
