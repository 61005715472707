import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { TokenHandler } from '../components/TokenHandler';
import { UnauthorizedPage } from '../pages/UnauthorizedPage';
import { Login } from '@modules/App/pages/Login';
import { AuthProtectedRoute } from '@shared/components/AuthProtectedRoute';
import { paths } from '@shared/enums/paths';
import { MyTeamPage } from '@modules/App/pages/MyTeamPage/MyTeamPage';
import { MyTeamHappinessPage } from '@modules/HappinessModule/pages/MyTeamHappinessPage/MyTeamHappinessPage';
import { MyTeamEvalPage } from '@modules/EvaluationModule/pages/MyTeamEvalPage/MyTeamEvalPage';
import { UserHappinessPage } from '@modules/HappinessModule/pages/UserHappinessPage/UserHappinessPage';
import { UserEvalPage } from '@modules/EvaluationModule/pages/UserEvalPage/UserEvalPage';
import { EvalPage } from '@modules/EvaluationModule/pages/EvalPage/EvalPage';
import { ClientEvalPage } from '@modules/EvaluationModule/pages/ClientEvalPage/ClientEvalPage';
import { MyPeopleEvalPage } from '@modules/EvaluationModule/pages/MyPeopleEvalPage/MyPeopleEvalPage';
import { SurveyManagementPage } from '@modules/HappinessModule/pages/SurveyManagementPage';
import { SurveyHistoryPage } from '@modules/HappinessModule/pages/SurveyHistoryPage';
import { UserPage } from '../pages/UserPage/UserPage';
import { ChatPage } from '@modules/HappinessModule/pages/ChatPage/ChatPage';
import { useObserveTitle } from '@shared/hooks/useObserveTitle';
import { EvalSettingsPage } from '@modules/EvaluationModule/pages/EvalSettingsPage/EvalSettingsPage';
import { EvalFormAndResultsPage } from '@modules/EvaluationModule/pages/EvalFormAndResultsPage/EvalFormAndResultsPage';
import { EvalSummaryPage } from '@modules/EvaluationModule/pages/EvalSummaryPage';
import { MyPeerReviewsPage } from '@modules/EvaluationModule/pages/MyPeerReviewsPage/MyPeerReviewsPage';
import { PeerEvalPage } from '@modules/EvaluationModule/pages/PeerEvalPage/PeerEvalPage';
import { RoutesWrapper } from '@shared/components/RoutesWrapper';
import { PageNotFound } from '../pages/PageNotFound';
import { OrgChartPage } from '../pages/OrgChartPage';
import { UserProfilePage } from '../pages/UserProfilePage';
import { CreateFlagFormPage } from '@modules/HappinessModule/pages/FlagPage/pages/CreateFlagFormPage/CreateFlagFormPage';
import { MyTeamFlagsPage } from '@modules/HappinessModule/pages/MyTeamFlagsPage/MyTeamFlagsPage';
import { UserFlagsPage } from '@modules/HappinessModule/pages/UserFlagsPage/UserFlagsPage';
import { PeopleHappinessPage } from '@modules/HappinessModule/pages/PeopleHappinessPage/PeopleHappinessPage';
import { ClientContactsPage } from '@modules/EvaluationModule/pages/ClientContactPage/ClientContactsPage';
import { AllFlagsPage } from '@modules/HappinessModule/pages/AllFlagsPage/AllFlagsPage';
import { EditFlagFormPage } from '@modules/HappinessModule/pages/FlagPage/pages/EditFlagFormPage/EditFlagFormPage';
import Bonuses from '@modules/EvaluationModule/pages/Bonuses/Bonuses';
import { UserCompensationPage } from '@modules/EvaluationModule/pages/UserCompensationPage/UserCompensationPage';
import { WhatIsNewPage } from '@modules/App/pages/WhatIsNewPage/WhatIsNewPage';
import { ClientSurveyResponsePage } from '@modules/HappinessModule/pages/ClientSurveyResponsePage';
import { NotProtectedRoute } from '@shared/components/NotProtectedRoute';
import { ClientSurveysPage } from '@modules/HappinessModule/pages/ClientSurveysPage';
import { pageAccessibilityByRoles } from '@shared/consts/pageAccessibilityByRoles';
import { PersonalRouteWrapper } from '@modules/EvaluationModule/pages/MyPeerReviewsPage/PersonalRouteWrapper';
import { ExternalLinksPage } from '@modules/App/pages/ExternalLinksPage/ExternalLinksPage';
import { UserGoalsPage } from '@modules/EvaluationModule/pages/UserGoalsPage/UserGoalsPage';
import { EvalEndOfProbationPage } from '@modules/EvaluationModule/pages/EvalEndOfProbationPage';
import { EditUserAddressComponent } from '../pages/UserProfilePage/componenes/EditUserAddressComponent';
import { SupportInfo } from '@modules/App/pages/SupportInfo';
import { UserPromotionsPage } from '@modules/EvaluationModule/pages/UserPromotionsPage/UserPromotionsPage';
import { HomeRedirectPage } from '@modules/HappinessModule/pages/HomeRedirectPage/HomeRedirectPage';
import { TermsOfUsePage } from '@modules/HappinessModule/pages/TermsOfUsePage';
import { UsersAlias } from '../pages/UsersAlias';
import { UserNotRegistered } from '../pages/UserNotRegistered';

export const AppRoutes: React.FC = () => {
  useObserveTitle();

  const {
    auth,
    login,
    unauthorized,
    users,
    surveyManagement,
    surveyHistory,
    myTeam,
    root,
    evaluations,
    responses,
    myPeopleEvals,
    chat,
    clientEval,
    peerForm,
    settings,
    summary,
    orgChart,
    clientContacts,
    profile,
    flags,
    allPeople,
    allFlags,
    peerReviews,
    compensation,
    clientSurveys,
    bonuses,
    whatIsNew,
    clientSurveyResponse,
    links,
    goals,
    edit,
    endOfProbation,
    supportInfo,
    promotions,
    termsOfUse,
    usersAlias,
    userNotRegistered,
    myProfile,
  } = paths;

  return (
    <Routes>
      <Route element={<RoutesWrapper />}>
        <Route path={clientEval} element={<ClientEvalPage />}>
          <Route path=":feedbackId" element={<ClientEvalPage />} />
        </Route>

        <Route element={<NotProtectedRoute />}>
          <Route path={`${clientSurveyResponse}/:clientSurveyResponseId`} element={<ClientSurveyResponsePage />} />
        </Route>

        <Route path={auth} element={<TokenHandler></TokenHandler>} />
        <Route path={login} element={<Login></Login>} />
        <Route path={unauthorized} element={<UnauthorizedPage></UnauthorizedPage>} />
        <Route path={userNotRegistered} element={<UserNotRegistered></UserNotRegistered>} />

        <Route element={<AuthProtectedRoute checkIfUserHasReporters isAvailableForManagers />}>
          <Route path={myTeam} element={<MyTeamPage />}>
            <Route path={responses} element={<MyTeamHappinessPage />}>
              <Route path=":questionId" element={<MyTeamHappinessPage />} />
            </Route>
            <Route path={evaluations} element={<MyTeamEvalPage />} />
            <Route path={flags} element={<MyTeamFlagsPage />} />
          </Route>
        </Route>

        <Route element={<AuthProtectedRoute isCheckHappinessAvailabilityInCurrentUser />}>
          <Route path={allPeople} element={<PeopleHappinessPage />}>
            <Route path=":questionId" element={<PeopleHappinessPage />} />
          </Route>
        </Route>

        <Route element={<AuthProtectedRoute allowedRoles={pageAccessibilityByRoles[clientContacts]} />}>
          <Route path={`${clientContacts}`} element={<ClientContactsPage />}></Route>
        </Route>

        <Route element={<AuthProtectedRoute allowedRoles={pageAccessibilityByRoles[clientSurveys]} />}>
          <Route path={clientSurveys} element={<ClientSurveysPage />} />
        </Route>

        <Route element={<AuthProtectedRoute />}>
          <Route path={orgChart} element={<OrgChartPage />}>
            <Route path=":selectedNodeId" element={<OrgChartPage />} />
          </Route>
          <Route path={root} element={<HomeRedirectPage></HomeRedirectPage>} />
          <Route path={myProfile} element={<HomeRedirectPage></HomeRedirectPage>} />

          <Route path={chat} element={<ChatPage />} />

          <Route path={peerForm} element={<PeerEvalPage />}>
            <Route path=":evalId" element={<PeerEvalPage />}>
              <Route path=":feedbackId" element={<PeerEvalPage />} />
            </Route>
          </Route>

          <Route path={whatIsNew} element={<WhatIsNewPage />} />

          <Route path={`${flags}/create/:id`} element={<CreateFlagFormPage />} />
          <Route path={`${flags}/:flagId`} element={<EditFlagFormPage />} />

          <Route path={`${usersAlias}/:emailUserName`} element={<UsersAlias />}>
            <Route path={`${bonuses}/:zohoId`} element={<UsersAlias />} />
            <Route path={compensation} element={<UsersAlias />} />
          </Route>
          <Route path={`${users}/:id`} element={<UserPage />}>
            <Route path={profile} element={<UserProfilePage />} />
            <Route path={promotions} element={<UserPromotionsPage />} />
            <Route path={edit} element={<EditUserAddressComponent />} />
            <Route path={responses} element={<UserHappinessPage />} />
            <Route path={goals} element={<UserGoalsPage />} />
            <Route path={evaluations} element={<UserEvalPage />} />
            <Route path={links} element={<ExternalLinksPage />}></Route>
            <Route path={flags} element={<UserFlagsPage />} />
            <Route
              path={peerReviews}
              element={
                <PersonalRouteWrapper>
                  <MyPeerReviewsPage />
                </PersonalRouteWrapper>
              }
            />
            <Route path={`${bonuses}/:zohoId`} element={<Bonuses />} />
            <Route path={compensation} element={<UserCompensationPage />} />
            <Route path={`${evaluations}/:evaluationId`} element={<EvalPage />}>
              <Route path="" element={<EvalFormAndResultsPage />} />
              <Route path={settings} element={<EvalSettingsPage />} />
              <Route path={summary} element={<EvalSummaryPage />} />
              <Route path={endOfProbation} element={<EvalEndOfProbationPage />} />
            </Route>
          </Route>

          <Route path={supportInfo} element={<SupportInfo />} />
        </Route>

        <Route element={<AuthProtectedRoute />}>
          <Route path={myPeopleEvals} element={<MyPeopleEvalPage />} />
        </Route>

        <Route element={<AuthProtectedRoute allowedRoles={pageAccessibilityByRoles[surveyManagement]} />}>
          <Route path={surveyManagement} element={<SurveyManagementPage />} />
        </Route>

        <Route element={<AuthProtectedRoute allowedRoles={pageAccessibilityByRoles[surveyHistory]} />}>
          <Route path={surveyHistory} element={<SurveyHistoryPage />} />
        </Route>
        <Route element={<AuthProtectedRoute />}>
          <Route path={allFlags} element={<AllFlagsPage />} />
          <Route path={termsOfUse} element={<TermsOfUsePage />} />
        </Route>

        <Route path="*" element={<PageNotFound></PageNotFound>}></Route>
      </Route>
    </Routes>
  );
};
