import React, { memo } from 'react';
import classnames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

import { useStyles } from './EvaluationHistoryTable.styles';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { UserEvalPageData } from '@modules/EvaluationModule/interfaces/UserEvalPageData';
import FormDone from '@mui/icons-material/PlaylistAddCheckOutlined';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { MyTeamEvalData } from '@modules/EvaluationModule/interfaces/MyTeamEvalData';
import { User } from '@shared/interfaces/user';
import { ActionButtons } from '@modules/EvaluationModule/components/MyTeamEvalReport/shared/ActionButtons';
import { EvaluationLinkButton } from '@modules/EvaluationModule/components/EvaluationHistory/components/EvaluationLinkButton/EvaluationLinkButton';
import { EvaluationPermissions } from '@modules/EvaluationModule/enums/evalPermissions';
import { userPermissionsSelector } from '@modules/App/redux/permissions/selectors';
import { getDateOfJoining } from '@shared/helpers/date';
import { EvalStatus } from '@modules/EvaluationModule/components/MyTeamEvalReport/shared/EvalStatus/EvalStatus';

interface Props {
  data?: UserEvalPageData | null;
  isDataLoading: boolean;
  currentUser: User;
  currentlyStartedEvalId?: string;
  onEvalStart?: (id: string) => void;
  onEopFail: (evaluationId: string, failReason: string) => void;
  failingEopId?: string;
  officeCountry?: string;
}

const EvaluationHistoryTable: React.FC<Props> = ({
  data,
  isDataLoading,
  currentlyStartedEvalId,
  onEvalStart,
  onEopFail,
  failingEopId,
  officeCountry,
}) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const userActionsPermissions = useSelector(userPermissionsSelector);
  const hasUpdatePermissions = userActionsPermissions.data?.permissions.includes(EvaluationPermissions.Update);

  const today = new Date();
  today.setMonth(0);
  today.setDate(30);
  today.setFullYear(2024);

  const tableHeader = (
    <tr className={styles.tableHeader}>
      <td className={styles.headerMonth}>Month</td>
      <td className={styles.headerManager}>Manager</td>
      <td className={styles.typeHeader}>Type</td>
    </tr>
  );

  const evalsDataTable = data?.history.map((evaluationHistoryEntry, i) => {
    const {
      date,
      evaluationId,
      primaryReportsTo,
      evalType,
      status,
      submittedByEvaluator,
      submittedByEvaluatee,
      googleCalendarEvent,
      isNonRocky,
      permissions: { isUpdatable },
      hasFeedbacks,
      eopResovledAt,
    } = evaluationHistoryEntry;

    const evaluation: MyTeamEvalData = {
      ...evaluationHistoryEntry,
      ...data.user,
      evaluationDate: date,
      isSubmittedByEvaluatee: submittedByEvaluatee,
      isSubmittedByEvaluator: submittedByEvaluator,
      googleCalendarEvent: googleCalendarEvent,
      userId: data.user.id || '',

      primaryReportsTo: {
        name: primaryReportsTo?.name || '',
        imageUrl: primaryReportsTo?.imageUrl || '',
        id: primaryReportsTo?.id || '',
        email: primaryReportsTo?.email || '',
      },
      peerReviews: [],
      clientReviews: [],
      isPrimaryReporter: false,
      evaluationRescheduleHistory: [],
      typeOfInteraction: evalType,
      hasFeedbacks,
      eopResolvedAt: eopResovledAt,
    };

    const isProcessingEval = currentlyStartedEvalId !== evaluationId && failingEopId !== evaluationId;

    return (
      <Tooltip title={isNonRocky ? 'Non-Rocky evaluation' : ''} key={i}>
        <tr
          className={classnames(styles.evalItem, {
            [styles.nonActiveEvalItem]: isNonRocky || status === EvaluationStatus.Upcoming,
            [styles.starting]: currentlyStartedEvalId === evaluationId,
            [styles.canceling]: failingEopId === evaluationId,
          })}
          onClick={() => {
            if (isNonRocky || status === EvaluationStatus.Upcoming) {
              return;
            }
            navigate(`/users/${data.user.id}/evaluations/${evaluationId}`);
          }}
        >
          <td className={styles.evalItemDate}>{format(parseISO(date), 'dd MMM yyyy')}</td>
          {primaryReportsTo ? (
            <td className={styles.managerBlock}>
              <small className={styles.participantLabel}>Manager</small>
              <RockyAvatar
                small
                fullName={primaryReportsTo?.name || ''}
                imageUrl={primaryReportsTo?.imageUrl}
              ></RockyAvatar>
              <div className={styles.managerName}>
                {primaryReportsTo?.name}
                {status === EvaluationStatus.Ongoing && submittedByEvaluator && (
                  <FormDone className={styles.formDoneIcon} />
                )}
              </div>
            </td>
          ) : (
            <td className={styles.noManagerBlock}>No data</td>
          )}

          <td className={styles.evalType}>
            <EvalStatus
              status={status}
              evaluationDate={date}
              eopResolvedAt={eopResovledAt}
              typeOfInteraction={evalType}
            />
          </td>
          <td className={styles.actionBtn}>
            {isProcessingEval ? (
              (isUpdatable || hasUpdatePermissions) && (
                <ActionButtons
                  evaluation={evaluation}
                  handleSendTheFormButtonClick={({ evaluationId }) => {
                    onEvalStart && onEvalStart(evaluationId);
                  }}
                  handleFailEopButtonClick={onEopFail}
                  officeCountry={officeCountry}
                />
              )
            ) : (
              <div className={styles.backdrop} onClick={(e) => e.stopPropagation()}></div>
            )}
          </td>
        </tr>
      </Tooltip>
    );
  });

  const evalsTableFooter = (
    <tr className={styles.tableFooter}>
      <td className={styles.startWorkDate}>{getDateOfJoining(data?.user?.employmentDate ?? null)}</td>
      <td className={styles.startWork}>Started working in AgileEngine 🎉</td>
    </tr>
  );

  const skeleton = <Skeleton className={styles.rowSkeleton} count={3} />;
  const singleSkeleton = <Skeleton className={styles.singleRowSkeleton} count={1} />;

  return (
    <table className={styles.root}>
      {isDataLoading ? (
        <thead>
          <tr>
            <td>{singleSkeleton}</td>
          </tr>
        </thead>
      ) : (
        <thead>{tableHeader}</thead>
      )}
      {isDataLoading ? (
        <tbody>
          <tr>
            <td>{skeleton}</td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {evalsDataTable}
          <tr>
            <td>{<EvaluationLinkButton />}</td>
          </tr>
        </tbody>
      )}
      {isDataLoading ? (
        <tfoot>
          <tr>
            <td>{singleSkeleton}</td>
          </tr>
        </tfoot>
      ) : (
        <tfoot>{evalsTableFooter}</tfoot>
      )}
    </table>
  );
};

export default memo(EvaluationHistoryTable);
