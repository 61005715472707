import { put, all, takeLatest, call, select, takeEvery } from 'redux-saga/effects';
import { DataState } from '@shared/enums/DataState';
import { getMyPeopleEvalData, setMyPeopleEvalDataLoading, setSelectedEvalsAction } from './actions';
import { MyTeamEvalData } from '@modules/EvaluationModule/interfaces/MyTeamEvalData';
import { fetchMyPeopleEvalData } from '@modules/EvaluationModule/api/myPeopleEvalData';
import { setMyPeopleEvalData } from './reducers';
import { AsyncData } from '@shared/interfaces/asyncData';
import { selectMyPeopleEvalData } from './selectors';
import { startSelectedEvals } from '@modules/EvaluationModule/api/startSelectedEvals';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { setStartingEvalId } from '../../MyTeamEvalPage/redux/actions';

function* getMyPeopleEvalDataAsync({ payload }: ReturnType<typeof getMyPeopleEvalData>) {
  if (payload.offset === 0) {
    const setLoadingAction = setMyPeopleEvalDataLoading();
    yield put(setLoadingAction);
  }

  try {
    const myPeopleEvalData: AsyncData<MyTeamEvalData[]> = yield select(selectMyPeopleEvalData);
    const response: MyTeamEvalData[] = yield call(fetchMyPeopleEvalData, payload);
    const data = myPeopleEvalData.data && response ? [...myPeopleEvalData.data, ...response] : response;
    const setMyPeopleEvalDataAction = setMyPeopleEvalData({
      data: data,
      state: DataState.Fulfilled,
    });
    yield put(setMyPeopleEvalDataAction);
  } catch {
    yield put(
      setMyPeopleEvalData({
        data: null,
        state: DataState.Rejected,
      })
    );
  }
}

function* setSelectedEvalsAsync({ payload }: ReturnType<typeof setSelectedEvalsAction>) {
  const evalIdToStart = payload.evaluationId;
  try {
    yield put(setStartingEvalId(evalIdToStart));
    yield call(startSelectedEvals, payload);
    const myPeopleEvalData: MyTeamEvalData[] = yield select(
      (state) => state.evaluation.myPeopleEvalPage.myPeopleEvalData.data
    );
    const newMyTeamEvalData = myPeopleEvalData.map((myTeamEval) => ({
      ...myTeamEval,
      status: evalIdToStart.includes(myTeamEval.evaluationId) ? EvaluationStatus.Ongoing : myTeamEval.status,
    }));

    const setMyTeamEvalDataAction = setMyPeopleEvalData({
      data: newMyTeamEvalData,
      state: DataState.Fulfilled,
    });
    const startedEval = myPeopleEvalData.find(({ evaluationId }) => evalIdToStart.includes(evaluationId));
    if (startedEval) {
      window.location.href = `/users/${startedEval.userId}/evaluations/${startedEval.evaluationId}`;
    }
    yield put(setMyTeamEvalDataAction);
    yield put(displayNotification('Evaluation preparations are now in progress  🙌'));
  } catch {
    yield put(displayNotification('Failed to start the evaluation 🥺'));
  }
  yield put(setStartingEvalId(''));
}
function* watchGetMyPeopleEvalData() {
  yield takeLatest(getMyPeopleEvalData.type, getMyPeopleEvalDataAsync);
  yield takeEvery(setSelectedEvalsAction.type, setSelectedEvalsAsync);
}

export function* myPeopleEvalDataPageSaga(): Generator {
  yield all([watchGetMyPeopleEvalData()]);
}
