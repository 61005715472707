import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useStyles } from './FeedbackForm.styles';
import { RockyButton } from '@shared/components/RockyButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Form, Formik, FormikProps } from 'formik';
import { FeedbackReasonLabels } from '../../enums/FeedbackReasonsLabels';
import { FeedbackReasonValues } from '../../enums/FeedbackReasonsValues';
import { FeedbackFormData } from '@modules/App/interfaces/FeedbackFormData';
import { FeedbackUserSelector } from '../FeedbackUserSelector/FeedbackUserSelector';
import { validationSchema } from '../../constants/validationSchema';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import {
  feedbackDataStateSelector,
  selectedManagerSelector,
  selectedUserSelector,
} from '@modules/App/redux/feedback/selectors';
import { DataState } from '@shared/enums/DataState';
import { FilePond } from 'react-filepond';
import { FilePondFile, FileStatus, registerPlugin } from 'filepond';
import { Alert, AlertTitle, Box } from '@mui/material';
import { FeedbackData } from '@modules/App/interfaces/feedback';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { useAuth } from '@shared/hooks/useAuth';
import { useLocation } from 'react-router-dom';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

interface Props {
  onSubmit: (data: Omit<FeedbackData, 'teamMember'>) => void;
  isOpen: boolean;
  closeFeedbackForm: () => void;
  initialValues: FeedbackFormData;
}

const FeedbackFormComponent: FC<Props> = ({ onSubmit, isOpen, closeFeedbackForm, initialValues }) => {
  const styles = useStyles();
  const { currentUser } = useAuth();
  const { pathname } = useLocation();
  const showWarning = currentUser?.hasReporters || pathname.includes('/bonuses') || pathname.includes('/compensation');
  const feedbackDataState = useSelector(feedbackDataStateSelector);
  const formRef = useRef<FormikProps<FeedbackFormData>>(null);
  const [isDirty, setIsDirty] = useState(false);
  const selectedUser = useSelector(selectedUserSelector);
  const selectedManager = useSelector(selectedManagerSelector);
  const isLoading = feedbackDataState === DataState.Pending;
  const [files, setFiles] = useState<FilePondFile[]>([]);

  const isAllFilesValid = files.every((file) => file.status === FileStatus.IDLE);

  const handleFormClose = () => {
    closeFeedbackForm();
    setIsDirty(false);
  };

  const handleReasonChange = (e: SelectChangeEvent<string>) => {
    formRef.current?.handleChange(e);
    setIsDirty(false);
  };

  useEffect(() => {
    if (!isLoading) {
      closeFeedbackForm();
      setFiles([]);
    }
  }, [isLoading]);

  const onFeedbackSubmit = useCallback(
    (values: FeedbackFormData) => {
      onSubmit({ ...values, attachmentFiles: files });
    },
    [selectedUser, onSubmit, files]
  );

  const handleClickSubmitIfValid = () => {
    if (!formRef.current) {
      return;
    }
    const values = formRef.current.values;
    formRef.current.validateForm().then((errors) => {
      if (values.feedbackReason === FeedbackReasonValues.IncorectStructure && (!selectedUser || !selectedManager)) {
        setIsDirty(true);
        return;
      }
      if (isEmpty(errors)) {
        formRef.current?.handleSubmit();
        setIsDirty(false);
      } else {
        setIsDirty(true);
      }
    });
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={() => handleFormClose()}>
        <DialogTitle>Report issue</DialogTitle>
        <DialogContent>
          {showWarning && (
            <Alert sx={{ marginBottom: 2 }} severity="warning" icon={'🤫'}>
              <AlertTitle>Any sensitive financial info must not be reported here</AlertTitle>
              Please do not share any financial information in your feedback. Make sure to hide $ numbers when attaching
              screenshots.
            </Alert>
          )}
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            innerRef={formRef}
            onSubmit={onFeedbackSubmit}
          >
            {(formik) => {
              const placeholder =
                formik.values.feedbackReason === FeedbackReasonValues.ContactPRTeam
                  ? 'Share your issue or concerns'
                  : 'Report a bug or suggest an improvement';

              const helperText =
                formik.values.feedbackReason === FeedbackReasonValues.Other
                  ? 'Dev team may email you to clarify details'
                  : formik.values.feedbackReason === FeedbackReasonValues.ContactPRTeam
                  ? 'Reach out to HR team if you have any concerns that you want to raise to someone else besides your manager or you need another point of escalation on a specific matter'
                  : 'HR support team may email you to clarify details';

              return (
                <Form className={styles.feedbackForm}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select
                        name="feedbackReason"
                        value={formik.values.feedbackReason}
                        onChange={(e) => handleReasonChange(e)}
                      >
                        <MenuItem value={FeedbackReasonValues.Other}>{FeedbackReasonLabels.Other}</MenuItem>
                        <MenuItem value={FeedbackReasonValues.IncorectStructure}>
                          {FeedbackReasonLabels.IncorectStructure}
                        </MenuItem>
                        <MenuItem value={FeedbackReasonValues.WrongEM}>{FeedbackReasonLabels.WrongEM}</MenuItem>
                        <MenuItem value={FeedbackReasonValues.IncorectProject}>
                          {FeedbackReasonLabels.IncorectProject}
                        </MenuItem>
                        <MenuItem value={FeedbackReasonValues.ContactPRTeam}>
                          {FeedbackReasonLabels.ContactPRTeam}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {(formik.values.feedbackReason === FeedbackReasonValues.IncorectStructure ||
                    formik.values.feedbackReason === FeedbackReasonValues.WrongEM) && (
                    <div className={styles.selectorsContainer}>
                      <div>
                        <FeedbackUserSelector
                          placeholder="Select team member"
                          teamMemberWithInccorectReportTo={formik.values.teamMemberWithInccorectReportTo}
                        />
                        {isDirty && !selectedUser && (
                          <span className={styles.fieldError}>Please select team member</span>
                        )}
                      </div>
                      <div>
                        <FeedbackUserSelector placeholder="Select correct manager" isManager />
                        {isDirty && !selectedManager && (
                          <span className={styles.fieldError}>Please select correct manager</span>
                        )}
                      </div>
                    </div>
                  )}
                  {formik.values.feedbackReason === FeedbackReasonValues.IncorectProject && (
                    <Grid item xs={12}>
                      <FeedbackUserSelector placeholder="Select team member" />
                      {isDirty && !selectedUser && <span className={styles.fieldError}>Please select team member</span>}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      name="feedbackText"
                      placeholder={placeholder}
                      helperText={
                        isDirty && formik.errors.feedbackText ? (
                          <span className={styles.fieldError}>{formik.errors.feedbackText}</span>
                        ) : (
                          helperText
                        )
                      }
                      InputLabelProps={{ shrink: true }}
                      minRows={10}
                      variant="outlined"
                      multiline
                      fullWidth
                      autoFocus
                      value={formik.values.feedbackText}
                      onChange={formik.handleChange}
                      error={isDirty && Boolean(formik.errors.feedbackText)}
                    />
                  </Grid>
                </Form>
              );
            }}
          </Formik>
          <Box mt={2}>
            <FilePond
              acceptedFileTypes={['image/*', 'video/quicktime', 'video/mp4', 'video/*']}
              files={files.map((file) => file.file)}
              onupdatefiles={setFiles}
              allowMultiple={true}
              maxFiles={3}
              maxFileSize="4MB"
              name="files"
              labelIdle={
                'Drag-n-Drop or <span class="filepond--label-action"><u>browse</u></span> screenshots or files</br>' +
                'Max 3 files, 4MB each'
              }
              credits={false}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            mt: '-20px',
          }}
        >
          <RockyButton inverted onClick={() => handleFormClose()}>
            Cancel
          </RockyButton>
          <RockyButton
            action
            onClick={() => handleClickSubmitIfValid()}
            isLoading={isLoading}
            disabled={!isAllFilesValid}
          >
            Send
          </RockyButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const FeedbackForm = memo(FeedbackFormComponent);
