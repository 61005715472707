import { ClientSurveyStatus } from '@modules/HappinessModule/enums/ClientSurveyStatus';

export const SEND_DATE_FORMAT = 'iii, MMM dd, yyyy';

export const ClientSurveyStatusLabel = {
  [ClientSurveyStatus.Scheduled]: 'Scheduled',
  [ClientSurveyStatus.Confirmed]: 'Confirmed',
  [ClientSurveyStatus.PendingConfirmation]: 'Pending Confirmation',
  [ClientSurveyStatus.Sending]: 'Sending',
  [ClientSurveyStatus.Sent]: 'Sent',
  [ClientSurveyStatus.Finished]: 'Finished',
  [ClientSurveyStatus.Overdue]: 'Overdue',
};

export const NEW_MODAL_KEY = 'NEW';
