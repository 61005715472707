import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Alert, Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';

import { YesNo } from '@shared/enums/yesNo';
import { TrueFalse } from '@shared/enums/TrueFalse';
import { CommunicationModalComponents } from '../../../../enums/CommunicationModalComponents';
import { useStyles } from '../CommunicationModal.styles';
import { Props as CommunicationModalProps } from '../CommunicationModal';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useSelector } from 'react-redux';
import { addFlagDataStateSelector } from '@modules/HappinessModule/pages/FlagPage/pages/CreateFlagFormPage/redux/selectors';
import { DataState } from '@shared/enums/DataState';
import { LoadingButton } from '@mui/lab';
import { useFormikContext } from 'formik';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';

const UserNotified: FC<Omit<CommunicationModalProps, 'step' | 'isOpen' | 'userFlagPermissions'>> = ({
  userInfo,
  onClose,
  setStep,
  isNewFlag,
  saveFlagWithNewConfidentiality,
}) => {
  const styles = useStyles();
  const [isUserNotified, setIsUserNotified] = useState<boolean | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsUserNotified(event.target.value === TrueFalse.True);
  };
  const addFlagDataState = useSelector(addFlagDataStateSelector);

  const isContinueDisabled = isUserNotified === null || isUserNotified === undefined;
  const { values } = useFormikContext<FlagFields>();

  const handleOnContinue = () => {
    if (isUserNotified) {
      saveFlagWithNewConfidentiality(false);
    } else {
      setStep(CommunicationModalComponents.ReminderMessage);
    }
  };

  useEffect(() => {
    if (addFlagDataState.loadingState === DataState.Fulfilled) {
      if (!values.assignedTom) {
        setStep(CommunicationModalComponents.EmailWillBeSentWhenUserIsAssignedToTom);
      } else {
        onClose();
      }
    }
  }, [addFlagDataState]);

  return (
    <>
      <Typography variant="subtitle1">Have you notified {userInfo.name} about NewHome?</Typography>
      <RadioGroup onChange={handleChange}>
        <FormControl>
          <Stack direction="row" spacing={0.5}>
            <FormControlLabel control={<Radio required />} label={YesNo.Yes} value={TrueFalse.True} />
            <FormControlLabel control={<Radio required />} label={YesNo.No} value={TrueFalse.False} />
          </Stack>
        </FormControl>
      </RadioGroup>
      <Typography variant="body2" marginTop={1}>
        Since the flag was rised in confidential mode, you have to discuss it with team mates during 1-on-1 meeting.
      </Typography>
      {isNewFlag && (
        <Stack marginTop="28px" rowGap="5px">
          <Alert icon={<SpellcheckIcon color="disabled" />} severity="info">
            <Typography variant="body2" fontSize="14px">
              Make sure you’ve written down everything correct.{' '}
            </Typography>
          </Alert>
          <Alert icon={<MailOutlineIcon color="disabled" />} severity="info">
            <Typography variant="body2" fontSize="14px">
              Raising flag will notify all the participants{' '}
            </Typography>
          </Alert>
        </Stack>
      )}

      <Box marginTop={5} className={styles.buttonsContainer}>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={addFlagDataState.loadingState === DataState.Pending}
          type="button"
          variant="contained"
          onClick={handleOnContinue}
          disabled={isContinueDisabled}
        >
          Continue
        </LoadingButton>
      </Box>
    </>
  );
};

export default UserNotified;
