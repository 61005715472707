import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMyTeamEvalData } from './hooks/useMyTeamEvalData';
import { setSortInfo } from './redux';
import { MyTeamEvalReport } from '@modules/EvaluationModule/components/MyTeamEvalReport/MyTeamEvalReport';
import { MyTeamEvalReportTable } from '@modules/EvaluationModule/components/MyTeamEvalReport/components/MyTeamEvalReportTable/MyTeamEvalReportTable';
import { DataState } from '@shared/enums/DataState';
import { RockyModulesTabs } from '@shared/components/RockyModulesTabs/RockyModulesTabs';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';
import { paths } from '@shared/enums/paths';
import useUserEnabledFeatures from '@shared/hooks/useUserEnabledFeatures';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import { SeeAllReportersLink } from '@modules/EvaluationModule/components/SeeAllReportersLink';

export const MyTeamEvalPage: React.FC = () => {
  const dispatch = useDispatch();
  const { myTeamEvalData, sortInfo } = useMyTeamEvalData();
  const onTableSortClick = useCallback((sortInfo) => dispatch(setSortInfo(sortInfo)), [dispatch]);
  const isDataLoading = myTeamEvalData.state === DataState.Pending;
  const setPageTitle = useTitle();
  const { isFlagsEnabled } = useUserEnabledFeatures();

  useEffect(() => {
    setPageTitle(pageTitles.myTeamEvalPage);
  }, [setPageTitle]);

  const primaryReportersEvals = myTeamEvalData.data?.filter((v) => v.isPrimaryReporter) || [];
  const isNoPrimaryReportersEvals = primaryReportersEvals.length === 0;

  const myTeamEvalDataFiltered = {
    ...myTeamEvalData,
    data: primaryReportersEvals,
  };

  return (
    <MyTeamEvalReport
      tableTitle="My team evaluations"
      myTeamEvalData={myTeamEvalDataFiltered}
      header={<RockyModulesTabs path={paths.myTeam} flags={isFlagsEnabled}></RockyModulesTabs>}
      sideBlock={
        isNoPrimaryReportersEvals ? null : (
          <SeeAllReportersLink
            path={paths.myPeopleEvals}
            linkTitle="See all evaluations"
            icon={<WorkspacePremiumOutlinedIcon />}
          />
        )
      }
    >
      <MyTeamEvalReportTable
        data={myTeamEvalDataFiltered.data}
        sortInfo={sortInfo}
        onSortClick={onTableSortClick}
        isDataLoading={isDataLoading}
      ></MyTeamEvalReportTable>
    </MyTeamEvalReport>
  );
};
