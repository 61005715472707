import { ClientSurveyData } from '@modules/HappinessModule/interfaces/ClientSurveyData';
import parseISO from 'date-fns/parseISO';
import { ClientSurveyStatus } from '@modules/HappinessModule/enums/ClientSurveyStatus';

const TIMEZONE_MAX_OFFSET = 14;
const TIME_OF_SENDING_THE_SURVEY = 10;

export const getMinSurveySendingDate = (): Date => {
  const today = new Date();
  const tommorow = new Date();
  tommorow.setDate(tommorow.getDate() + 1);
  const currentTimezoneOffset = Math.abs(today.getTimezoneOffset() / 60);
  const differenceInTimezonesOffset = Math.abs(currentTimezoneOffset - TIMEZONE_MAX_OFFSET);
  const timeWhenSendingEmailsClosed = today.getHours() + differenceInTimezonesOffset;
  return timeWhenSendingEmailsClosed < TIME_OF_SENDING_THE_SURVEY ? today : tommorow;
};

export const getSurveySendingValidDate = (date: Date): Date => {
  const surveySendingDate = new Date(date.toUTCString());
  surveySendingDate.setHours(TIME_OF_SENDING_THE_SURVEY);
  surveySendingDate.setUTCHours(TIME_OF_SENDING_THE_SURVEY);
  surveySendingDate.setUTCMinutes(0);
  surveySendingDate.setUTCSeconds(0);
  surveySendingDate.setUTCMilliseconds(0);
  return surveySendingDate;
};

export const getNextDate = (clientSurveys: ClientSurveyData[] | null): string => {
  if (clientSurveys && clientSurveys.length) {
    const oldestSurvey = clientSurveys?.sort((a, b) => parseISO(b.date).getTime() - parseISO(a.date).getTime())[0];
    const oldestSurveyDate = new Date(oldestSurvey.date);
    oldestSurveyDate.setMonth(oldestSurveyDate.getMonth() + 1, 1);
    return getSurveySendingValidDate(oldestSurveyDate).toISOString();
  }

  const today = new Date();
  const nextMonthFirstDay = new Date(today);
  nextMonthFirstDay.setMonth(nextMonthFirstDay.getMonth() + 1, 1);
  return getSurveySendingValidDate(nextMonthFirstDay).toISOString();
};

export const isClientSurveyEditable = (status: ClientSurveyStatus | null): boolean =>
  status
    ? [ClientSurveyStatus.Scheduled, ClientSurveyStatus.PendingConfirmation, ClientSurveyStatus.Overdue].includes(
        status
      )
    : true;

export const filterCsatSurveysByDateRange = ({
  csatSurveys,
  filterByDateRange,
}: {
  csatSurveys: ClientSurveyData[];
  filterByDateRange: number;
}): ClientSurveyData[] => {
  if (filterByDateRange) {
    return csatSurveys.filter((survey) => {
      const surveyDate = new Date(survey.date);
      return surveyDate.getFullYear() === Number(filterByDateRange);
    });
  } else {
    return csatSurveys;
  }
};
