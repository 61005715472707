import { ClietContactFormData } from '@modules/EvaluationModule/interfaces/ClietContactFormData';

export const ClientContactFromInitialValues: ClietContactFormData = {
  firstName: '',
  lastName: '',
  title: '',
  customTitle: '',
  workstream: '',
  emailAeTool: '',
  technicalPerson: false,
  location: '',
  locationArea: '',
  rmTypeOfContact: 'Client',
  doNotTouch: false,
  feedbackGiver: true,
  rmContactStatus: 'Current employee',
  csatGiver: false,
  csatEmailsEnabled: false,
  csatGiverLowFrequency: false,
};
