import { ChangeEvent, FC, useEffect, SyntheticEvent, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Rating,
  Select,
  Stack,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { FastField, Field, FieldProps, FormikProps, useFormikContext } from 'formik';
import { format, isDate, isValid } from 'date-fns';
import classNames from 'classnames';

import { useStyles } from './NewHomeNeededBlock.styles';
import { AssignedTomSelector } from './AssignedTomSelector';
import { FlagFormFields } from '../../constants/FlagFormFields';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { FlagPermissionsData } from '@modules/HappinessModule/pages/FlagPage/interfaces/FlagPermissionsData';
import { Workloads } from '@shared/enums/Workloads';
import { NewHomeResolution } from '@modules/HappinessModule/enums/NewHomeResolution';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { ZohoRecruitLink } from '../../../ZohoRecruitLink';
import { checkIfZohoRecruitLinkShown } from '@modules/HappinessModule/pages/FlagPage/helpers/checkIfZohoRecruitLinkShown';
import LastEvalsTable from './LastEvalsTable';
import AddIcon from '@mui/icons-material/Add';
import CommunicationModal from './CommunicationModal/CommunicationModal';
import { useModalState } from '@shared/hooks/useModalState';
import useQuery from '@shared/hooks/useQuery';
import { CommunicationModalComponents } from '../../enums/CommunicationModalComponents';
import { NewHomeEmailSentStatus } from '@modules/HappinessModule/enums/NewHomeEmailSentStatus';
import { editFlagAction } from '@modules/HappinessModule/pages/FlagPage/pages/CreateFlagFormPage/redux/actions';
import { NewHomeAvailableFromTypes } from '@modules/HappinessModule/enums/NewHomeAvailableFromTypes';
import { NewHomeResolutionErrorModal } from './NewHomeResolutionErrorModal';
import { NewHomeProjectFound } from './NewHomeProjectFound';
import { FlagFieldBlockPreview } from '@modules/HappinessModule/pages/FlagPage/pages/EditFlagFormPage/components/FlagPreview/components/FlagFieldBlockPreview';
import { NewHomeProjectFoundPreview } from '@modules/HappinessModule/pages/FlagPage/pages/EditFlagFormPage/components/FlagPreview/components/NewHomeNeededBlockPreview/components/NewHomeProjectFoundPreview';
import { TrueFalse } from '@shared/enums/TrueFalse';

interface Props {
  userFlagPermissions: FlagPermissionsData;
  isNewFlag?: boolean;
  userData: UserInfo;
  isCommunicationModalOpen?: boolean;
  communicationModalFunctions: ReturnType<typeof useModalState>;
  handleRaisingFlag: (values: FlagFields) => void;
}

export const NewHomeNeededBlock: FC<Props> = ({
  userFlagPermissions,
  isNewFlag,
  userData,
  communicationModalFunctions: { openModal, isModalOpen, closeModal },
  handleRaisingFlag,
}) => {
  const styles = useStyles();
  const { flagId } = useParams();
  const [modalStep, setModalStep] = useState<CommunicationModalComponents>(CommunicationModalComponents.UserNotified);
  const dispatch = useDispatch();
  const { errors, values, setFieldValue, submitCount, isSubmitting, submitForm, initialValues } =
    useFormikContext<FlagFields>();

  const query = useQuery();
  const isConfidentialQuery = query.get('isConfidential');
  const isModalOpenInitial = isConfidentialQuery === TrueFalse.False && Boolean(values.isConfidential);

  useEffect(() => {
    if (isModalOpenInitial) {
      openModal();
    }
  }, [isModalOpenInitial]);

  const {
    openModal: openNewHomeProjectFoundModal,
    isModalOpen: isNewHomeProjectFoundModalOpen,
    closeModal: closeNewHomeProjectFoundModal,
  } = useModalState(false);
  const [isNewHomeProjectFoundSetted, setIsNewHomeProjectFoundSetted] = useState(
    initialValues.newHomeResolution === NewHomeResolution.ProjectFound
  );

  useEffect(() => {
    if (values.newHomeResolution === NewHomeResolution.ProjectFound && !isNewHomeProjectFoundSetted) {
      openNewHomeProjectFoundModal();
    }
  }, [values.newHomeResolution, isNewHomeProjectFoundSetted]);

  const isNewHomeSectionFieldsDisabled = isNewFlag
    ? !userFlagPermissions.newHomeSection.isCreatable
    : !userFlagPermissions.newHomeSection.isUpdatable;

  const isNewHomeSectionReadable =
    userFlagPermissions.newHomeSection.isReadable || userFlagPermissions.newHomeSection.isCreatable;
  const isNewHomeTomSectionReadable =
    userFlagPermissions.newHomeTomSection.isReadable || userFlagPermissions.newHomeTomSection.isCreatable;

  const isNewHomeTomSectionFieldsDisabled = isNewFlag
    ? !userFlagPermissions.newHomeTomSection.isCreatable
    : !userFlagPermissions.newHomeTomSection.isUpdatable;

  const isZohoRecruitLinkShown = checkIfZohoRecruitLinkShown({
    isNewHomeTomSectionReadable,
    isNewHomeNeeded: values.isNewHomeNeeded ?? false,
    zohoRecruitId: userData?.zohoRecruitId ?? null,
  });

  const [isTechnicalFeedbackShown, setIsTechnicalFeedbackShown] = useState(false);

  const isWaitingForNHDetails = !values.pros || !values.cons || !values.softSkills;

  useEffect(() => {
    setModalStep(
      isWaitingForNHDetails
        ? CommunicationModalComponents.WaitingForNHDetails
        : CommunicationModalComponents.UserNotified
    );
  }, [isWaitingForNHDetails]);

  const handleCloseModal = useCallback(() => {
    closeModal();
    if (isWaitingForNHDetails) {
      submitForm();
    } else {
      setModalStep(CommunicationModalComponents.UserNotified);
    }
  }, [isWaitingForNHDetails]);

  const userId = userData.id;

  const saveFlagWithNewConfidentiality = (isConfidential: boolean) => {
    if (isNewFlag) {
      handleRaisingFlag({
        ...values,
        isConfidential,
      });
      return;
    } else if (flagId) {
      dispatch(
        editFlagAction({
          fields: {
            isConfidential,
            isNewHomeNeeded: values.isNewHomeNeeded,
            pros: values.pros,
            cons: values.cons,
            softSkills: values.softSkills,
          },
          flagId,
          isNoNavigation: true,
        })
      );
    }
    setFieldValue(FlagFormFields.isConfidential.name, isConfidential);
  };

  const handleNumericChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
    let value = Number(e.target.value);
    if (value < 0) {
      value = 0;
      return;
    } else if (value > 1000) {
      value = 1000;
    }
    setFieldValue(fieldName, value);
  };
  const {
    openModal: openNHResolutionErrorModal,
    isModalOpen: isNHResolutionErrorModalOpen,
    closeModal: closeNHResolutionErrorModal,
  } = useModalState(isModalOpenInitial);

  useEffect(() => {
    if (isSubmitting && errors.newHomeResolution && Object.keys(errors).length === 1) {
      openNHResolutionErrorModal();
    }
  }, [isSubmitting, errors]);

  const isHewHomeResolutionDisabled =
    values.numOptionsShown === null || values.numSubmissions === null || values.numTICIFailed === null;

  return !isNewHomeSectionReadable ? null : (
    <>
      <Paper variant="elevation" elevation={2}>
        <Grid container rowGap="36px" className={styles.mainContainer}>
          <Grid item xs={4}>
            <Field
              name={FlagFormFields.isNewHomeNeeded.name}
              type="checkbox"
              component={({ field }: FieldProps) => (
                <FormControlLabel
                  control={<Switch {...field} disabled={isNewHomeSectionFieldsDisabled} />}
                  label={<Typography variant="h2">{FlagFormFields.isNewHomeNeeded.label}</Typography>}
                />
              )}
            />
          </Grid>
          <Grid item xs={8}>
            <Stack
              flexDirection="row"
              columnGap="10px"
              alignItems="center"
              sx={{
                height: '100%',
              }}
            >
              {isZohoRecruitLinkShown ? (
                <ZohoRecruitLink user={userData} />
              ) : (
                <>
                  <GroupsOutlinedIcon color="disabled" />
                  <Typography variant="body2">
                    Add more info if person needs a new project. Learn more in{' '}
                    <Link
                      target="_blank"
                      href="https://docs.google.com/document/d/10-8K1lHBu5qf8GB8eZ6SUFQc0MO-2MSQ4IlSEMXwOps/edit#"
                    >
                      AE NewHome process↗
                    </Link>
                  </Typography>
                </>
              )}
            </Stack>
          </Grid>
          {values.isNewHomeNeeded && (
            <>
              <Grid item xs={12}>
                <Grid container columnSpacing={3}>
                  <Grid item xs={4} paddingRight={4}>
                    <Field
                      name={FlagFormFields.isPassive.name}
                      type="checkbox"
                      component={({ field }: FieldProps) => (
                        <FormControlLabel
                          control={
                            <Checkbox disabled={isNewHomeSectionFieldsDisabled} {...field} value={field.value ?? ''} />
                          }
                          label={FlagFormFields.isPassive.label}
                        />
                      )}
                    />
                    <Typography variant="body2">
                      Project change may be needed, but there is no risk of attrition within the next 2 month
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    paddingRight={4}
                    className={classNames({ [styles.notConfidentail]: !values.isConfidential && !isNewFlag })}
                  >
                    {values.isConfidential || isNewFlag ? (
                      <>
                        <Field
                          name={FlagFormFields.isConfidential.name}
                          type="checkbox"
                          component={({ field }: FieldProps) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  disabled={isNewHomeSectionFieldsDisabled}
                                  value={field.value ?? ''}
                                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    if (isNewFlag) field.onChange(event);
                                    else if (!event.target.checked) openModal();
                                  }}
                                />
                              }
                              label={FlagFormFields.isConfidential.label}
                            />
                          )}
                        />
                        <Typography variant="body2">
                          NewHome process should be started without notifying the teammate
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography marginBottom="8px" mt="8px">
                          Not Confidentail anymore
                        </Typography>
                        <Typography variant="body2" marginBottom="8px">
                          {!values.assignedTom ? (
                            'Automated NH email will be sent once the responsible TOM is assigned.'
                          ) : (
                            <>
                              {values.nhEmailSentStatus === NewHomeEmailSentStatus.NotRequested
                                ? `NewHome process was communicated with ${userData.name} and an automated NH email will be sent once the flag is saved.`
                                : `NewHome process was communicated with ${userData.name} and automated NH email was sent.`}
                            </>
                          )}
                        </Typography>
                      </>
                    )}
                  </Grid>

                  <Grid item xs={4} paddingRight={4}>
                    <Field
                      name={FlagFormFields.isReturnedFromSabbatical.name}
                      type="checkbox"
                      component={({ field }: FieldProps) => (
                        <FormControlLabel
                          control={
                            <Checkbox {...field} disabled={isNewHomeSectionFieldsDisabled} value={field.value ?? ''} />
                          }
                          label={FlagFormFields.isReturnedFromSabbatical.label}
                        />
                      )}
                    />
                    <Typography variant="body2">
                      And there's no possibility to assign team member to the project where they were before sabbatical
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <AssignedTomSelector disabled={isNewHomeTomSectionFieldsDisabled} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Timelines</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <DatePicker
                      disabled={isNewHomeSectionFieldsDisabled}
                      label={FlagFormFields.lastDayOnTheProject.label}
                      views={['year', 'month', 'day']}
                      openTo="month"
                      inputFormat="dd MMM yyyy"
                      value={values[FlagFormFields.lastDayOnTheProject.name]}
                      disableMaskedInput
                      onChange={(newDate: unknown) => {
                        let parsedNewDate = newDate;
                        if (isDate(newDate) && isValid(newDate)) {
                          const date = format(newDate as Date, 'yyyy-MM-dd');
                          parsedNewDate = `${date}T00:00:00.000`;
                        }
                        setFieldValue(FlagFormFields.lastDayOnTheProject.name, parsedNewDate, true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          helperText={errors.lastDayOnTheProject}
                          error={Boolean(errors.lastDayOnTheProject)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DatePicker
                      maxDate={values[FlagFormFields.newHomeEndDate.name]}
                      disabled={isNewHomeTomSectionFieldsDisabled}
                      label={FlagFormFields.newHomeStartDate.label}
                      views={['year', 'month', 'day']}
                      openTo="month"
                      inputFormat="dd MMM yyyy"
                      value={values[FlagFormFields.newHomeStartDate.name]}
                      disableMaskedInput
                      onChange={(newDate: unknown) => {
                        let parsedNewDate = newDate;
                        if (isDate(newDate) && isValid(newDate)) {
                          parsedNewDate = (newDate as Date).toISOString();
                        }

                        setFieldValue(FlagFormFields.newHomeStartDate.name, parsedNewDate, true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          helperText={errors.newHomeStartDate}
                          error={Boolean(errors.newHomeStartDate)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DatePicker
                      minDate={values[FlagFormFields.newHomeStartDate.name]}
                      disabled={isNewHomeTomSectionFieldsDisabled}
                      label={FlagFormFields.newHomeEndDate.label}
                      views={['year', 'month', 'day']}
                      openTo="month"
                      inputFormat="dd MMM yyyy"
                      value={values[FlagFormFields.newHomeEndDate.name]}
                      disableMaskedInput
                      onChange={(newDate: unknown) => {
                        let parsedNewDate = newDate;
                        if (isDate(newDate) && isValid(newDate)) {
                          parsedNewDate = (newDate as Date).toISOString();
                        }

                        setFieldValue(FlagFormFields.newHomeEndDate.name, parsedNewDate, true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          helperText={errors.newHomeEndDate}
                          error={Boolean(errors.newHomeEndDate)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name={FlagFormFields.newHomeAvailableFromType.name}
                      component={Autocomplete}
                      value={values.newHomeAvailableFromType}
                      onChange={(_: SyntheticEvent, newValue: string) => {
                        setFieldValue(FlagFormFields.newHomeAvailableFromType.name, newValue);
                        setFieldValue(FlagFormFields.newHomeAvailableFrom.name, null);
                      }}
                      options={Object.values(NewHomeAvailableFromTypes)}
                      disabled={isNewHomeTomSectionFieldsDisabled}
                      disableClearable
                      limitTags={1}
                      renderInput={(params: FormikProps<TextFieldProps>) => (
                        <TextField
                          {...params}
                          label={FlagFormFields.newHomeAvailableFromType.label}
                          disabled={isNewHomeTomSectionFieldsDisabled}
                        />
                      )}
                    />
                  </Grid>
                  {values.newHomeAvailableFromType === NewHomeAvailableFromTypes.ExactDate && (
                    <Grid item xs={4}>
                      <DatePicker
                        disabled={isNewHomeTomSectionFieldsDisabled}
                        label={FlagFormFields.newHomeAvailableFrom.label}
                        views={['year', 'month', 'day']}
                        openTo="month"
                        inputFormat="dd MMM yyyy"
                        value={values[FlagFormFields.newHomeAvailableFrom.name]}
                        disableMaskedInput
                        onChange={(newDate: unknown) => {
                          let parsedNewDate = newDate;
                          if (isDate(newDate) && isValid(newDate)) {
                            parsedNewDate = (newDate as Date).toISOString();
                          }

                          setFieldValue(FlagFormFields.newHomeAvailableFrom.name, parsedNewDate, true);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            helperText={errors.newHomeAvailableFrom}
                            error={Boolean(errors.newHomeAvailableFrom && submitCount)}
                          />
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={3} md={2}>
                    <Field
                      type="select"
                      name={FlagFormFields.workload.name}
                      component={({ field }: FieldProps) => (
                        <FormControl disabled={isNewHomeSectionFieldsDisabled} fullWidth>
                          <InputLabel id={FlagFormFields.workload.name}>{FlagFormFields.workload.label}</InputLabel>
                          <Select
                            labelId={FlagFormFields.workload.name}
                            variant="outlined"
                            label={FlagFormFields.workload.label}
                            {...field}
                          >
                            {Object.values(Workloads).map((workload) => (
                              <MenuItem key={workload} value={workload}>
                                {workload}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                  Manager's feedback *
                </Typography>
                <Grid container spacing={2}>
                  {[FlagFormFields.pros, FlagFormFields.cons, FlagFormFields.softSkills].map(
                    ({ name, label, helperText }) => (
                      <Grid item xs={4} key={name}>
                        <FastField name={name}>
                          {({ field }: FieldProps) => (
                            <TextField
                              error={!!(errors[name] && submitCount)}
                              disabled={isNewHomeSectionFieldsDisabled}
                              size="medium"
                              label={label}
                              variant="outlined"
                              minRows={4}
                              multiline
                              fullWidth
                              helperText={helperText}
                              {...field}
                            />
                          )}
                        </FastField>
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {!isTechnicalFeedbackShown &&
                !(values.technicalCons || values.technicalPros || values.technicalSoftSkills) ? (
                  <Link component="button" onClick={() => setIsTechnicalFeedbackShown(true)}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <AddIcon />
                      Add technical feedback
                    </Stack>
                  </Link>
                ) : (
                  <>
                    <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                      Technical feedback
                    </Typography>
                    <Grid container spacing={2}>
                      {[
                        FlagFormFields.technicalPros,
                        FlagFormFields.technicalCons,
                        FlagFormFields.technicalSoftSkills,
                      ].map(({ name, label }) => (
                        <Grid item xs={4} key={name}>
                          <FastField name={name}>
                            {({ field }: FieldProps) => (
                              <TextField
                                disabled={isNewHomeSectionFieldsDisabled}
                                size="medium"
                                label={label}
                                variant="outlined"
                                minRows={4}
                                multiline
                                fullWidth
                                {...field}
                              />
                            )}
                          </FastField>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                  NewHome results
                </Typography>
                <Stack direction="row" spacing="24px">
                  {[FlagFormFields.numOptionsShown, FlagFormFields.numSubmissions, FlagFormFields.numTICIFailed].map(
                    ({ name, label }) => (
                      <Grid item xs={2} key={name}>
                        <Field name={name}>
                          {({ field }: FieldProps) => (
                            <TextField
                              disabled={isNewHomeTomSectionFieldsDisabled}
                              size="medium"
                              type="number"
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', max: 1000, min: 0 }}
                              label={label}
                              variant="outlined"
                              fullWidth
                              {...field}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => handleNumericChange(e, name)}
                              value={field.value ?? ''}
                            />
                          )}
                        </Field>
                      </Grid>
                    )
                  )}
                </Stack>
                <Grid container spacing={2} sx={{ marginTop: '2px' }}>
                  <Grid item xs={4}>
                    {isNewHomeProjectFoundSetted ? (
                      <FlagFieldBlockPreview
                        label={FlagFormFields.newHomeResolution.label}
                        value={values.newHomeResolution}
                      />
                    ) : (
                      <Field
                        type="select"
                        name={FlagFormFields.newHomeResolution.name}
                        component={({ field }: FieldProps) => (
                          <FormControl
                            fullWidth
                            disabled={isNewHomeTomSectionFieldsDisabled || isHewHomeResolutionDisabled}
                          >
                            <InputLabel id={FlagFormFields.newHomeResolution.name}>
                              {FlagFormFields.newHomeResolution.label}
                            </InputLabel>
                            <Select
                              labelId={FlagFormFields.newHomeResolution.name}
                              variant="outlined"
                              label={FlagFormFields.newHomeResolution.label}
                              {...field}
                            >
                              {Object.values(NewHomeResolution).map((newHomeResolution) => (
                                <MenuItem key={newHomeResolution} value={newHomeResolution}>
                                  {newHomeResolution === NewHomeResolution.ProjectFound
                                    ? `${NewHomeResolution.ProjectFound}...`
                                    : newHomeResolution}
                                </MenuItem>
                              ))}
                            </Select>
                            {isHewHomeResolutionDisabled && (
                              <FormHelperText>Please first specify the amounts above</FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Stack rowGap="6px">
                      <Typography variant="body2">{FlagFormFields.starRate.label}</Typography>
                      <Rating
                        disabled={isNewHomeTomSectionFieldsDisabled}
                        value={values[FlagFormFields.starRate.name]}
                        max={10}
                        onChange={(_, value) => setFieldValue(FlagFormFields.starRate.name, value)}
                      />
                    </Stack>
                  </Grid>

                  {values.newProjectAssignment && isNewHomeProjectFoundSetted && (
                    <Grid item xs={12}>
                      <NewHomeProjectFoundPreview newProjectAssignmentData={values.newProjectAssignment} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <LastEvalsTable userId={userId} />
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
      <CommunicationModal
        isNewFlag={isNewFlag ?? false}
        setStep={setModalStep}
        saveFlagWithNewConfidentiality={saveFlagWithNewConfidentiality}
        step={modalStep}
        userInfo={userData}
        userFlagPermissions={userFlagPermissions}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
      <NewHomeResolutionErrorModal isOpen={isNHResolutionErrorModalOpen} handleClose={closeNHResolutionErrorModal} />
      {isNewHomeProjectFoundModalOpen && (
        <NewHomeProjectFound
          user={userData}
          isOpen={isNewHomeProjectFoundModalOpen}
          handleClose={closeNewHomeProjectFoundModal}
          setIsNewHomeProjectFoundSetted={setIsNewHomeProjectFoundSetted}
        />
      )}
    </>
  );
};
