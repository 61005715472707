import { EvalPageData } from '@modules/EvaluationModule/interfaces/EvalPageData';
import { EvaluationOptionsData } from '@modules/EvaluationModule/interfaces/EvaluationOptionsData';
import { createAction } from '@reduxjs/toolkit';
import { AsyncData } from 'shared/interfaces/asyncData';
import { EvaluationCloseData } from '@modules/EvaluationModule/interfaces/EvaluationCloseData';
import { EopResult } from '@modules/EvaluationModule/enums/EopResult';

interface EopFullResultPayload extends Omit<EvaluationCloseData, 'autosave'> {
  eopResult: EopResult;

  closeEvalToo?: boolean;
}

export const getUserEvalPageData = createAction<string>('evalPage/getUserEvalPageData');
export const setUserEvalPageData = createAction<AsyncData<EvalPageData>>('evalPage/setUserEvalPageData');
export const closeEval = createAction<EvaluationCloseData>('evalPage/closeEval');
export const submitEvalForm = createAction<EvaluationOptionsData>('evalPage/submitEvalForm');
export const autoSaveEvalForm = createAction<EvaluationOptionsData>('evalPage/autoSaveEvalForm');
export const autosaveEvalNotesAndGoals = createAction<EvaluationCloseData>('evalPage/autosaveEvalNotesAndGoals');
export const setFullEop = createAction<EopFullResultPayload>('evalPage/setFullEop');
export const getUserEvalPermissions = createAction<string>('evalPage/getUserEvalPermissionsAction');
export const getUserEvalPromotion = createAction<string>('evalPage/getUserEvalPromotion');
