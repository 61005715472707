import { apiUrl, apiUrlNode } from '@configs/environment';
import httpClient from '@shared/api/httpClient';
import httpNodeClient from '@shared/api/httpNodeClient';
import { EvalPageData } from '../interfaces/EvalPageData';
import { EvaluationOptionsData } from '@modules/EvaluationModule/interfaces/EvaluationOptionsData';
import { getEvalPageDataFromResponse, mapEvaluationOptionsData } from './helpers/evaluationResponse';
import { EvalResponseData } from './types';
import { EvaluationCloseData } from '../interfaces/EvaluationCloseData';
import { AxiosResponse } from 'axios';
import { EvalEopPayload } from '../interfaces/EvalEopPayload';
import getEvaluationPermissions from './helpers/getEvaluationPermissions';
import parseISO from 'date-fns/parseISO';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import formatISO from 'date-fns/formatISO';
import { UserPromotionsData } from '../interfaces/UserPromotionsData';

export const fetchEvalPageData = (evaluationId: string): Promise<EvalPageData> =>
  httpClient
    .get<EvalResponseData>(`${apiUrl}/evaluations/${evaluationId}/`)
    .then((resp) => getEvalPageDataFromResponse(resp.data));

export const patchEvalPageData = (evaluation: EvaluationOptionsData, evalId: string): Promise<EvalPageData> => {
  const mappedPayload = mapEvaluationOptionsData(evaluation);

  return httpClient.put<EvalResponseData>(`${apiUrl}/evaluations/${evalId}/`, mappedPayload).then((resp) => {
    return getEvalPageDataFromResponse(resp.data);
  });
};

export const patchCloseEval = (evalId: string, closeEvalData: EvaluationCloseData): Promise<AxiosResponse> => {
  // quick fix until a better solution is found
  const nextEvalDate = closeEvalData.next_evaluation_date;
  const now = new Date();
  if (nextEvalDate && now > parseISO(nextEvalDate)) {
    closeEvalData.next_evaluation_date = formatISO(addMonths(endOfMonth(now), 1));
  }

  return httpClient.post<EvalResponseData>(`${apiUrl}/evaluations/${evalId}/close/`, closeEvalData);
};

export const updateEvalEopStatus = (evalId: string, evalEop: EvalEopPayload): Promise<AxiosResponse> => {
  return httpNodeClient.post(`${apiUrlNode}/admin/evaluations/${evalId}/eop-status/`, {
    eopStatus: evalEop.eopStatus,
    eopExtendedTo: evalEop.nextEvaluationDate,
  });
};

export const fetchUserEvalPermissions = (evalId: string) =>
  httpNodeClient.get(`${apiUrlNode}/evaluations-history/${evalId}/permissions`).then(({ data }) => {
    return getEvaluationPermissions(data);
  });

export const fetchRelatedToEvalPromotion = (evalId: string) =>
  httpNodeClient.get<UserPromotionsData>(`/evaluations-history/${evalId}/promotions`).then(({ data }) => data);
