import { ClientSurveyData } from '@modules/HappinessModule/interfaces/ClientSurveyData';
import { SurveyPendingDeletionData } from '@modules/HappinessModule/interfaces/SurveyPendingDeletionData';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';
import { SLICE_NAME } from './constants';
import { State } from './types';
import { NEW_MODAL_KEY } from '../constants/constants';

const initialState: State = {
  clientSurveys: {
    data: null,
    state: DataState.Pending,
  },

  upsertSurvey: undefined,
  upsertState: DataState.Idle,

  confirmModalOpen: false,
  confirmActionPending: false,

  categoriesList: { data: null, state: DataState.Idle },
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setClientSurveysData(state, action: PayloadAction<AsyncData<ClientSurveyData[]>>) {
      state.clientSurveys = action.payload;
    },
    setClientSurveysDataLoading(state) {
      state.clientSurveys = { data: null, state: DataState.Pending };
    },
    setUpsertSurveyState(state, action: PayloadAction<DataState>) {
      state.upsertState = action.payload;
    },
    setUpsertSurvey(state, action: PayloadAction<ClientSurveyData | typeof NEW_MODAL_KEY | undefined>) {
      state.upsertSurvey = action.payload;
    },
    setClientSurveyConfirmModalOpen(state, action: PayloadAction<{ open: boolean; pending: boolean }>) {
      const { open, pending } = action.payload;
      state.confirmModalOpen = open;
      state.confirmActionPending = pending;
    },
    setConfirmClientSurvey(state, action: PayloadAction<ClientSurveyData>) {
      state.clientSurveys.data =
        state.clientSurveys.data?.map((survey) => {
          if (survey.id === action.payload.id) {
            return action.payload;
          }

          return survey;
        }) || [];
      state.confirmModalOpen = false;
      state.confirmActionPending = false;
    },
    setClientSurveysState(state, action: PayloadAction<{ state: DataState }>) {
      state.clientSurveys.state = action.payload.state;
    },
    removeClientSurveyFromListOfSurveys(state, action: PayloadAction<SurveyPendingDeletionData>) {
      if (!state.clientSurveys.data) {
        return;
      }
      state.clientSurveys.data = state.clientSurveys.data?.filter(({ id }) => id !== action.payload.id);
    },
    setCategoriesList(state, action: PayloadAction<State['categoriesList']>) {
      state.categoriesList = action.payload;
    },
    clearListOfCategories(state) {
      state.categoriesList = {
        data: null,
        state: DataState.Idle,
      };
    },
  },
});

export const {
  setClientSurveysData,
  setClientSurveysDataLoading,
  setUpsertSurveyState,
  setUpsertSurvey,
  removeClientSurveyFromListOfSurveys,
  setConfirmClientSurvey,
  setClientSurveysState,
  setClientSurveyConfirmModalOpen,
  setCategoriesList,
  clearListOfCategories,
} = slice.actions;

export default slice.reducer;
