import { ClietContactFormData } from '@modules/EvaluationModule/interfaces/ClietContactFormData';

type ClientContactFormFieldsTypeMap = {
  [key in keyof Required<Omit<ClietContactFormData, 'id'>>]: {
    name: key;
    label: string;
  };
};
export const ClientContactFormFields: ClientContactFormFieldsTypeMap = {
  firstName: {
    name: 'firstName',
    label: 'First Name',
  },
  lastName: {
    name: 'lastName',
    label: 'Last Name',
  },
  title: {
    name: 'title',
    label: 'Title',
  },
  customTitle: {
    name: 'customTitle',
    label: 'Please specify',
  },
  workstream: {
    name: 'workstream',
    label: 'Works Stream',
  },
  emailAeTool: {
    name: 'emailAeTool',
    label: 'Email',
  },
  technicalPerson: {
    name: 'technicalPerson',
    label: 'Technical person',
  },
  location: {
    name: 'location',
    label: 'Location',
  },
  locationArea: {
    name: 'locationArea',
    label: 'Location Area',
  },
  rmTypeOfContact: {
    name: 'rmTypeOfContact',
    label: 'Type of contact',
  },
  doNotTouch: {
    name: 'doNotTouch',
    label: 'Ask with automated email',
  },
  feedbackGiver: {
    name: 'feedbackGiver',
    label: 'Ask for evaluation feedback',
  },
  csatGiver: {
    name: 'csatGiver',
    label: 'Include in CSAT surveys',
  },
  csatGiverLowFrequency: {
    name: 'csatGiverLowFrequency',
    label: '',
  },
  rmContactStatus: {
    name: 'rmContactStatus',
    label: 'Contact Status',
  },
  csatEmailsEnabled: {
    name: 'csatEmailsEnabled',
    label: 'CSAT emails enabled',
  },
};
